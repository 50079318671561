.slick-track {
  display: flex;
  gap: 9px;
}

.detail-desciption {
  & strong {
    color: #2980b9
  }

  & a {
    color: #3498db !important;
    text-decoration: none;
  }

  & .text-black {
    color: #000 !important;
  }

  & .detail-desciption-footer {
    color: #e64d4d !important;

    & a {
      color: blue !important
    }
  }

  & .text-primary {
    color: #EF4444 !important;
  }

  & .category {
    & strong {
      color: black !important;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;

  & input {
    width: 100% !important;
    padding: 10px !important;
    border: 1px solid transparent;
    border-radius: 5px;
    outline: none;
    text-align: center;
  }

  & input[type="text"] {
    padding-block: unset;
    padding-inline: unset
  }
}

.react-datepicker__month-container {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid #cfcfcf;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: 1px solid #e5e5e5;
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  color: #cfcfcf;
  border: 1px solid #cfcfcf;
}

.react-datepicker__day--in-range {
  background-color: #EA2424 !important;
  color: white !important
}

.react-datepicker__day--keyboard-selected {
  background-color: #FDE1D2 !important;
  color: #EA2424 !important
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range) {
  background-color: #FDE1D2 !important;
  color: #EA2424 !important
}

@keyframes myfirst-data-v-6458f359 {
  0% {
    background: #ee4a09
  }

  50% {
    background: #dc2626
  }

  to {
    background: #ee4a09
  }
}